<template>
  <div>
    <!--<component :is="'script'" v-html="jsonld" type="application/ld+json"></component>-->
    <div class="hero">
      <div class="hero__container bb-container">
        <div class="hero__content">
          <div class="hero__claim">
            <h1 class="header-1">
              Haz tu lista de nacimiento con regalos de cualquier tienda
            </h1>
            <a
              v-show="isAuthenticated"
              @click.prevent="$router.push({ name: 'owner-list' })"
              class="button button--lg button--accent"
              >Empieza tu lista ahora</a
            >
            <a
              v-show="!isAuthenticated"
              @click="$router.push({ name: 'signup' })"
              class="button button--lg button--accent"
              >Empieza tu lista ahora</a
            >
          </div>
          <div class="hero__cover">
            <img fetchpriority="high" src="../../assets/img/covers/cover-portada.jpg" />
          </div>
        </div>
      </div>
    </div>

    <div class="g-tiendas mar-b-3">
      <div class="bb-container g-tiendas__container">
        <h3 class="header-5 mar-b-2">Añade regalos de tiendas como</h3>
        <div class="g-tiendas__tiendas">
          <div class="g-tiendas__item">
            <img loading="lazy" src="../../assets/img/logos/logo-af-corte-ingles.svg" />
          </div>
          <div class="g-tiendas__item">
            <img loading="lazy" src="../../assets/img/logos/logo-af-amazon.svg" />
          </div>
          <div class="g-tiendas__item">
            <img loading="lazy" src="../../assets/img/logos/logo-af-ikea.svg" />
          </div>
          <div class="g-tiendas__item">
            <img loading="lazy" src="../../assets/img/logos/logo-af-etsy.svg" />
          </div>
          <div class="g-tiendas__item">
            <img loading="lazy" src="../../assets/img/logos/logo-af-tutete.png" />
          </div>
          <div class="g-tiendas__item">
            <img loading="lazy" src="../../assets/img/logos/logo-af-mundobebes.png" />
          </div>
        </div>
      </div>
    </div>

    <section class="mar-b-4">
      <div class="bb-container">
        <div class="g-feat">
          <div class="g-feat__content">
            <h2 class="header-2">Tu lista cuando tu quieras</h2>
            <div class="page-body">
              <p>
                Haz tu lista como prefieras, desde la comodidad de tu casa o con la
                flexibilidad de tu móvil.
              </p>
            </div>
            <LazyHydrate when-visible>
              <CtaAppLinks />
            </LazyHydrate>
          </div>
          <div class="g-feat__cover">
            <img loading="lazy" src="../../assets/img/covers/cover-devices.jpg" />
          </div>
        </div>

        <div class="g-feat g-feat--inverse">
          <div class="g-feat__content">
            <h2 class="header-2">Elige cualquier regalo de cualquier tienda</h2>
            <div class="page-body">
              <p>Libertad total para escoger justo lo que quieres.</p>
            </div>
          </div>
          <div class="g-feat__cover">
            <img loading="lazy" src="../../assets/img/covers/cover-elige.jpg" />
          </div>
        </div>

        <div class="g-feat">
          <div class="g-feat__content">
            <h2 class="header-2">Recibe regalos o efectivo</h2>
            <div class="page-body">
              <p>
                Tu familia y amigos podrán regalarte cosas o mandarte dinero para que las
                compres tú.
              </p>
              <p>
                <router-link
                  class="link link--bold link--underline"
                  to="hucha-de-nacimiento#comoFunciona"
                >
                  Lee más sobre la Hucha de nacimiento</router-link
                >.
              </p>
            </div>
          </div>
          <div class="g-feat__cover">
            <img
              loading="lazy"
              src="../../assets/img/covers/cover-regalos-efectivo.svg"
            />
          </div>
        </div>

        <div class="g-feat g-feat--inverse">
          <div class="g-feat__content">
            <h2 class="header-2">Gana un 5% de lo que gastéis</h2>
            <div class="page-body">
              <p>
                Obtendrás un cheque regalo por valor de un 5% de todo lo que tú, tus
                familiares y tus amigos compréis en la tienda de HelloBB (si llegáis a los
                500€).
              </p>
              <p>
                <router-link
                  class="link link--bold link--underline"
                  to="programa-de-recompensas"
                >
                  Lee más sobre nuestro Programa de Recompensas</router-link
                >.
              </p>
            </div>
          </div>
          <div class="g-feat__cover">
            <img loading="lazy" src="../../assets/img/covers/cover-cashback.svg" />
          </div>
        </div>
      </div>
    </section>

    <section class="pad-4 bg-dark">
      <div class="bb-container">
        <div class="titulos titulos--center titulos--50 titulos--light">
          <h2 class="header-2 color-white">
            Pide también cosas que no venden en ninguna tienda
          </h2>
          <p>(pero que necesitas de verdad)</p>
        </div>

        <div class="g-pide">
          <div class="g-pide__item">
            <div class="g-pide__picture">
              <img
                loading="lazy"
                class="g-pide__ico"
                src="../../assets/img/ico/ico-round-fondos.svg"
                alt=""
              />
            </div>
            <div class="g-pide__content">
              <h3 class="header-4 mar-b-1">Fondos económicos</h3>
              <p>
                Reúne dinero para pañales, vacunas, canguros, una ONG de tu elección...
                ¡Lo que quieras!
              </p>
            </div>
          </div>
          <div class="g-pide__item">
            <div class="g-pide__picture">
              <img
                loading="lazy"
                class="g-pide__ico"
                src="../../assets/img/ico/ico-round-comercios-locales.svg"
                alt=""
              />
            </div>
            <div class="g-pide__content">
              <h3 class="header-4">Regalos de productores locales</h3>
              <p>
                Pide regalos únicos que no están en las grandes superficies. Apoya a
                pequeños productores independientes y al comercio local.
              </p>
            </div>
          </div>
          <div class="g-pide__item">
            <div class="g-pide__picture">
              <img
                loading="lazy"
                class="g-pide__ico"
                src="../../assets/img/ico/ico-round-favores.svg"
                alt=""
              />
            </div>
            <div class="g-pide__content">
              <h3 class="header-4">Ayudas y favores</h3>
              <p>
                Pide a los que más te quieren que te echen una mano. Que te den ropa que
                sus hijos ya no usan, que te traigan comida, que te ayuden con la plancha,
                que paseen a tu perro...
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="pad-5 bg-testimonials bg-principal-light">
      <div class="bb-container">
        <div class="grid-3">
          <div class="grid-3__item">
            <div class="b-card">
              <div class="testimonial-item">
                <img
                  loading="lazy"
                  class="testimonial-item__avatar"
                  src="../../assets/img/covers/testimonial-1.png"
                  alt=""
                />
                <div class="testimonial-item__text">
                  "Pude poner objetos que jamás hubiera encontrado en una sola tienda. Muy
                  práctico."
                </div>
                <div class="testimonial-item__name">Ana F.</div>
              </div>
            </div>
          </div>
          <div class="grid-3__item">
            <div class="b-card">
              <div class="testimonial-item">
                <img
                  loading="lazy"
                  class="testimonial-item__avatar"
                  src="../../assets/img/covers/testimonial-2.png"
                  alt=""
                />
                <div class="testimonial-item__text">
                  "Las guías y comentarios son muy útiles. No tenía idea de lo que
                  realmente necesitaba."
                </div>
                <div class="testimonial-item__name">Estefanía C.</div>
              </div>
            </div>
          </div>
          <div class="grid-3__item">
            <div class="b-card">
              <div class="testimonial-item">
                <img
                  loading="lazy"
                  class="testimonial-item__avatar"
                  src="../../assets/img/covers/testimonial-3.png"
                  alt=""
                />
                <div class="testimonial-item__text">
                  "Nuestros amigos nos han regalado justo lo que queríamos y han
                  conseguido el mejor precio."
                </div>
                <div class="testimonial-item__name">Carlos P.</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="pad-4 bg-grey-6" v-if="featureBlogAvailable">
      <div class="bb-container">
        <div class="g-ademas">
          <div class="g-ademas__content">
            <h2 class="header-2">Queremos estar a tu lado</h2>
            <div class="page-body mb-0">
              <p>
                Preparar la llegada de un bebé es una experiencia maravillosa, pero no nos
                engañemos, durante el proceso surgen muchas preguntas que pueden resultar
                estresantes.
              </p>

              <p>
                <strong
                  >Echa un vistazo a nuestras guías para aprender, inspirarte y
                  decidir.</strong
                >
              </p>
            </div>
          </div>
          <div class="g-ademas__actions">
            <div class="b-card b-card--hover">
              <a href="https://www.hellobb.net/blog" class="action-featured">
                <div class="action-featured__head">
                  <img
                    loading="lazy"
                    class="action-featured__ico"
                    src="../../assets/img/ico/ico-blog.svg"
                    alt=""
                  />
                  <h2 class="header-3 mar-b-1">Blog</h2>
                </div>
                <p>Responde a tus preguntas e inspírate para tu próxima decisión.</p>
              </a>
            </div>
            <div class="b-card b-card--hover">
              <a
                href="https://www.hellobb.net/blog/categorias/listas-de-ejemplo"
                class="action-featured"
              >
                <div class="action-featured__head">
                  <img
                    loading="lazy"
                    class="action-featured__ico"
                    src="../../assets/img/ico/ico-list.svg"
                    alt=""
                  />
                  <h2 class="header-3 mar-b-1">Listas de ejemplo</h2>
                </div>
                <p>Empieza tu lista rápido y con buen pie.</p>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="pad-4">
      <div class="bb-container">
        <LazyHydrate when-visible>
          <CtaYellow></CtaYellow>
        </LazyHydrate>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import LazyHydrate from "vue-lazy-hydration";

import Features from "../../Mixins/Features";

export default {
  name: "Home",
  mixins: [
    Features,
  ],
  metaInfo() {
    return {
      script: [
        {
          type: "application/ld+json",
          json: {
            "@context": "https://schema.org",
            "@type": "SoftwareApplication",
            name: "HelloBB",
            operatingSystem: [
              //no estic segur del format per posar més d’un valor
              "ANDROID",
              "IOS",
            ],
            applicationCategory: "LifestyleApplication",
            aggregateRating: {
              "@type": "AggregateRating",
              ratingValue: "4.5", //no aconsegueixo veure ratings, tu pots?
              ratingCount: "20", //no aconsegueixo veure ratings, tu pots?
            },
            offers: {
              "@type": "Offer",
              price: "0",
            },
          },
        },
        {
          type: "application/ld+json",
          json: {
            "@context": "https://schema.org",
            "@type": "Organization",
            name: "HelloBB",
            legalName: "HelloBB S.L.",
            url: "https://www.hellobb.net",
            logo: "https://www.hellobb.net/logo-hellobb-dark.svg", //posar URL on tenim el logo
            description:
              "Pon en tu lista de nacimiento cualquier cosa de cualquier tienda.",
            foundingDate: "2022",
            address: {
              "@type": "PostalAddress",
              streetAddress: "C/ Pompeu Fabra 36",
              addressLocality: "Tortosa",
              addressRegion: "Tarragona",
              postalCode: "43500",
              addressCountry: {
                "@type": "Country",
                name: "Spain", //en anglés millor?
              },
            },
            contactPoint: {
              "@type": "ContactPoint",
              contactType: "customer support",
              email: "hola@hellobb.net",
            },
            sameAs: [
              "https://www.instagram.com/holahellobebe/",
              "https://www.facebook.com/holahelloBB",
              "https://www.pinterest.es/holahelloBB/",
              "https://www.crunchbase.com/organization/hellobb",
            ],
          },
        },
      ],
      title: "HelloBB | Tu lista de nacimiento sin límites",
      meta: [
        {
          name: "description",
          content:
            "Una lista de nacimiento digital en la que puedes poner cualquier cosa de cualquier tienda. Fácil de usar y compartir. ¡Tan única como tú!",
        },
        {
          itemprop: "image",
          content: "https://www.hellobb.net/images/cover-portada.08a0882.png",
        },
        {
          property: "og:url",
          content: "https://www.hellobb.net",
        },
        {
          property: "og:type",
          content: "website",
        },
        {
          property: "og:title",
          content: "HelloBB | Tu lista de nacimiento sin límites",
        },
        {
          property: "og:description",
          content:
            "Una lista de nacimiento digital en la que puedes poner cualquier cosa de cualquier tienda. También puedes recaudar dinero y pedir favores. ¡Tan única como tú!",
        },
        {
          property: "og:image",
          content: "https://www.hellobb.net/images/cover-portada.08a0882.png",
        },
        {
          name: "twitter:card",
          content: "summary_large_image",
        },
        {
          name: "twitter:title",
          content: "HelloBB | Tu lista de nacimiento sin límites",
        },
        {
          name: "twitter:description",
          content:
            "Una lista de nacimiento digital en la que puedes poner cualquier cosa de cualquier tienda. También puedes recaudar dinero y pedir favores. ¡Tan única como tú!",
        },
        {
          name: "twitter:image",
          content: "https://www.hellobb.net/images/cover-portada.08a0882.png",
        },
      ],
      link: [
        { rel: "canonical", href: "https://www.hellobb.net" },
        { rel: "alternate", href: "https://www.hellobb.net/fr", hreflang: "fr" },
        { rel: "alternate", href: "https://www.hellobb.net/it", hreflang: "it" },
        { rel: "alternate", href: "https://www.hellobb.net", hreflang: "es-es" },
        { rel: "alternate", href: "https://www.hellobb.net/latam", hreflang: "es-mx" },
        { rel: "alternate", href: "https://www.hellobb.net/latam", hreflang: "es-co" },
        { rel: "alternate", href: "https://www.hellobb.net/latam", hreflang: "es-ar" },
        { rel: "alternate", href: "https://www.hellobb.net/latam", hreflang: "es-cl" },
        { rel: "alternate", href: "https://www.hellobb.net/latam", hreflang: "es-uy" },
        { rel: "alternate", href: "https://www.hellobb.net/latam", hreflang: "es-pe" },
      ],
    };
  },
  components: {
    CtaYellow: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "CtaYellow" */ "../cta/CtaYellow"
      ),
    CtaAppLinks: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "CtaAppLinks" */ "../cta/CtaAppLinks"
      ),
    LazyHydrate,
  },
  computed: {
    ...mapGetters({
      isAuthenticated: "isAuthenticated",
      user: "user",
      myList: "myList",
      currency: "loggedInUserCurrency",
      hidePiggyBank: "hidePiggyBank",
    }),
  },
  methods: {
    cta_redirect: function () {
      if (this.isAuthenticated) {
        this.$router.push("/mi-lista").catch((err) => {});
      }
      this.$router.push("/signup").catch((err) => {});
    },

  },
  mounted() {
    //this.currencySymbol = this.currency && this.currency.symbol;
  }
};
</script>
<style scoped></style>
