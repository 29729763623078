<template>
    <div>
      <header class="hero-int">
        <div class="bb-container">
          <div class="hero-int__container">
            <div class="hero-int__left">
              <h1 class="hero-int__title">
                La lista nascita per le famiglie di oggi
              </h1>
              <div class="hero-int__actions">
                <bbButton
                  label="Inizia la tua lista ora"
                  class="button--lg button--accent"
                  @click="$router.push({ name: 'creez-votre-liste' })"
                />
                <a @click="$router.push({ name: 'guest', params: { id: exampleListId } })" class="link link--bold link--underline">
                    Vedi un esempio di lista
                </a>
              </div>
            </div>
            <div class="hero-int__cover">
              <picture>
                <source
                  srcset="../../assets/img/covers/cover-fr-hero@2x.png"
                  media="(min-width: 1399px) and (min-device-pixel-ratio: 2)"
                />
                <img src="../../assets/img/covers/cover-fr-hero.png" alt="" />
              </picture>
            </div>
          </div>
        </div>
      </header>
      <section class="rate-app">
        <div class="bb-container">
          <div class="rate-app__container">
            <div class="rate-app__title">
                Più di 50.000 famiglie hanno creato la loro lista nascita con HelloBB
            </div>
            <div class="rate-app__card">
              <div class="rate-app__item">
                <div class="rate-app__logo">
                  <img src="../../assets/img/ico/ico-google-play.svg" alt="Google Play" />
                </div>
                <div class="rate-app__rate">4,8</div>
                <div class="rate-app__star">
                  <img src="../../assets/img/ico/ico-star-fill.svg" alt="" />
                </div>
              </div>
              <hr />
              <div class="rate-app__item">
                <div class="rate-app__logo">
                  <img src="../../assets/img/ico/ico-app-store.svg" alt="Apple App Store" />
                </div>
                <div class="rate-app__rate">4,8</div>
                <div class="rate-app__star">
                  <img src="../../assets/img/ico/ico-star-fill.svg" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="tiendas-list">
        <div class="tiendas-list__title">
            Aggiungi prodotti da qualsiasi negozio
        </div>
        <div class="tiendas-list__items">
          <VueSlickCarousel v-bind="shopSlider">
            <div class="tiendas-list__item">
              <img loading="lazy" src="../../assets/img/logos/logo-fr-amazon.png" />
            </div>
            <div class="tiendas-list__item">
              <img
                loading="lazy"
                src="../../assets/img/logos/logo-fr-berceau-magique.png"
              />
            </div>
            <div class="tiendas-list__item">
              <img loading="lazy" src="../../assets/img/logos/logo-fr-autour-bebe.png" />
            </div>
            <div class="tiendas-list__item">
              <img loading="lazy" src="../../assets/img/logos/logo-fr-petit-bateau.png" />
            </div>
            <div class="tiendas-list__item">
              <img loading="lazy" src="../../assets/img/logos/logo-fr-vertbaudet.png" />
            </div>
            <div class="tiendas-list__item">
              <img
                loading="lazy"
                src="../../assets/img/logos/logo-fr-maisons-du-monde.png"
              />
            </div>
            <div class="tiendas-list__item">
              <img loading="lazy" src="../../assets/img/logos/logo-fr-aubert.png" />
            </div>
            <div class="tiendas-list__item">
              <img loading="lazy" src="../../assets/img/logos/logo-fr-orchestra.png" />
            </div>
            <div class="tiendas-list__item">
              <img
                loading="lazy"
                src="../../assets/img/logos/logo-fr-larmoire-de-bebe.png"
              />
            </div>
            <div class="tiendas-list__item">
              <img loading="lazy" src="../../assets/img/logos/logo-fr-smallable.png" />
            </div>
            <div class="tiendas-list__item">
              <img
                loading="lazy"
                src="../../assets/img/logos/logo-fr-bebe-au-naturel.png"
              />
            </div>
            <div class="tiendas-list__item">
              <img loading="lazy" src="../../assets/img/logos/logo-fr-kiabi.png" />
            </div>
          </VueSlickCarousel>
        </div>
      </section>
      <section class="hbb-features">
        <div class="bb-container">
          <div class="hbb-features__title">
            La lista nascita digitale e multimarca
          </div>
          <div class="hbb-features__items">
            <div class="hbb-features__item">
              <div class="hbb-features__ico">
                <img loading="lazy" src="../../assets/img/ico/ico-color-list-heart.svg" />
              </div>
              <div class="hbb-features__footer">
                <div class="hbb-features__h">La più bella e su misura per te</div>
                <div class="hbb-features__sh">
                    Questo momento unico merita qualcosa che sia all'altezza.
                </div>
              </div>
            </div>
            <div class="hbb-features__item">
              <div class="hbb-features__ico">
                <img loading="lazy" src="../../assets/img/ico/ico-color-click-mobile.svg" />
              </div>
              <div class="hbb-features__footer">
                <div class="hbb-features__h">Facile da usare per tutti</div>
                <div class="hbb-features__sh">
                    Zero problemi per te e per le persone con cui vorrai condividere i tuoi desideri.
                </div>
              </div>
            </div>
            <div class="hbb-features__item">
              <div class="hbb-features__ico">
                <img loading="lazy" src="../../assets/img/ico/ico-color-gratis-it.svg" />
              </div>
              <div class="hbb-features__footer">
                <div class="hbb-features__h">Gratuita e senza impegno</div>
                <div class="hbb-features__sh">
                    Crea la tua lista gratuitamente e senza alcun obbligo di acquisto.
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="app-avanzada">
        <div class="bb-container">
          <div class="app-avanzada__container">
            <aside class="app-avanzada__left">
              <div class="app-avanzada__title">L'unica app specifica per le liste nascita</div>
              <div class="app-avanzada__description">
                Dimentica i sistemi che non sono stati progettati per il tuo cellulare.
              </div>
              <div class="app-avanzada__actions">
                <!-- <a href="#" class="link link--bold link--underline">
                  Découvrez notre application gratuite
                </a> -->
                Scopri la nostra app gratuita con tutto il necessario per creare la tua lista perfetta.
              </div>
              <div class="app-avanzada__stores" target="_blank">
                <a :href="$t('links.mobileAppUrlAndroid', 'fr-FR')">
                  <img
                    loading="lazy"
                    src="../../assets/img/logos/logo-app-google.fr-FR.svg"
                    alt=""
                    width="140"
                    height="40"
                  />
                </a>
                <a :href="$t('links.mobileAppUrlIos', 'fr-FR')" target="_blank">
                  <img
                    loading="lazy"
                    src="../../assets/img/logos/logo-app-appstore.fr-FR.svg"
                    alt=""
                    width="140"
                    height="40"
                  />
                </a>
              </div>
            </aside>
            <picture class="app-avanzada__cover">
              <img
                src="../../assets//img/covers/mockup-llista.png"
                :alt="$t('home2.appTitle')"
              />
            </picture>
          </div>
        </div>
      </section>
      <main class="home-beneficios">
        <div class="bb-container">
          <div class="home-beneficios__items">
            <section class="home-beneficios__item">
              <div class="home-beneficios__cover">
                <img src="../../assets/img/covers/cover-fr-heart-sale.png" alt="" />
              </div>
              <div class="home-beneficios__right">
                <div class="home-beneficios__title">
                  Scegli qualsiasi prodotto da qualsiasi negozio
                </div>
                <div class="home-beneficios__description">
                    Hai la totale libertà di scegliere proprio quello che vuoi.
                </div>
                <ul class="home-beneficios__list">
                  <li>Prodotti unici di piccoli brand</li>
                  <li>Le migliori offerte dei grandi magazzini</li>
                </ul>
              </div>
            </section>
            <section class="home-beneficios__item">
              <div class="home-beneficios__cover">
                <img
                  src="../../assets//img/covers/cover-it-recibiendo-dinero.jpg"
                  alt="Prête pour recevoir de l'argent dans votre cagnotte."
                />
              </div>
              <div class="home-beneficios__right">
                <div class="home-beneficios__title">
                    Pronta a ricevere denaro nel tuo salvadanaio nascita
                </div>
                <div class="home-beneficios__description">
                    La tua lista nascita include un salvadanaio con il quale potrai raccogliere denaro per acquisti specifici o per altri progetti.
                </div>
                <div class="home-beneficios__actions">
                  <div class="link link--bold link--underline">
                    Pour plus d'informations
                  </div>
                </div>
              </div>
            </section>
            <section class="home-beneficios__item">
              <div class="home-beneficios__cover">
                <img
                  src="../../assets//img/covers/mockup-aportacions.png"
                  alt="Offrez la possibilité de se cotiser pour les achats les plus chers"
                />
              </div>
              <div class="home-beneficios__right">
                <div class="home-beneficios__title">
                    Possibilità di dividere le spese dei prodotti più costosi
                </div>
                <div class="home-beneficios__description">
                    La tua famiglia e i tuoi amici potranno contribuire all'acquisto dei prodotti più costosi.
                </div>
              </div>
            </section>
          </div>
        </div>
      </main>
      <div class="feedback-int">
        <div class="bb-container">
          <div class="feedback-int__title">
            Alcune belle parole dei nostri utenti
          </div>
          <div class="feedback-int__items">
            <VueSlickCarousel v-bind="reviewsSlider">
              <div class="feedback-int__item">
                <div class="feedback-user">
                  <div class="feedback-user__cover">
                    <img src="../../assets/img/covers/testimonial-marta.jpg" alt="" />
                  </div>
                  <div class="feedback-user__content">
                    <div class="feedback-user__string">
                      “Quest'app è fantastica per organizzare la lista nascita! Ha molte opzioni ed è molto intuitiva. Consigliatissima!”
                    </div>
                    <div class="feedback-user__name">Marthe</div>
                  </div>
                </div>
              </div>
              <div class="feedback-int__item">
                <div class="feedback-user">
                  <div class="feedback-user__cover">
                    <img src="../../assets/img/covers/testimonial-estefania.jpg" alt="" />
                  </div>
                  <div class="feedback-user__content">
                    <div class="feedback-user__string">
                      “Ottima soluzione per rendere più semplice la questione regali quando nasce un bambino! È super facile da usare e molto utile. La consiglio❤️.”
                    </div>
                    <div class="feedback-user__name">Stéphanie</div>
                  </div>
                </div>
              </div>
              <div class="feedback-int__item">
                <div class="feedback-user">
                  <div class="feedback-user__cover">
                    <img src="../../assets/img/covers/testimonial-ichamar.jpg" alt="" />
                  </div>
                  <div class="feedback-user__content">
                    <div class="feedback-user__string">
                      “Application très utile et intuitive pour faire la wish-list du bébé.
                      Je la recommande à 100 %App super utile e intuitiva per fare la wishlist del bebè. Consigliatissima.”
                    </div>
                    <div class="feedback-user__name">Pierre</div>
                  </div>
                </div>
              </div>
            </VueSlickCarousel>
          </div>
        </div>
      </div>
      <section class="acomp">
        <div class="bb-container">
          <div class="acomp__container">
            <div class="acomp__left">
              <div class="acomp__title">Ti accompagniamo nella tua gravidanza💛</div>
              <div class="acomp__description">
                <p>
                    Prepararsi all'arrivo di un bambino è un'esperienza meravigliosa, ma diciamo la verità: durante il processo sorgono molte domande che possono essere stressanti.
                </p>
                <p>
                    Le nostre email risolveranno i tuoi dubbi e saranno un simpatico promemoria del tuo calendario di gravidanza.
                </p>
              </div>
            </div>
            <div class="acomp__right">
              <img
                src="../../assets/img/covers/cover-it-acompanamiento.jpg"
                alt="Préparer l’arrivée d’un bébé est une expérience merveilleuse, mais soyons francs, c’est aussi un processus au cours duquel on peut se poser de nombreuses questions et cela peut être stressant."
              />
            </div>
          </div>
        </div>
      </section>
      <section class="cta-yellow-fw">
        <div class="bb-container">
          <div class="cta-yellow-fw__cta">
            <div class="cta-yellow-fw__left">
              <div class="cta-yellow-fw__title">
                E tu, hai già tutto pronto per l'arrivo del tuo bambino?
              </div>
              <div class="cta-yellow-fw__actions">
                <bbButton
                  label="Inizia la tua lista ora"
                  class="button--accent button--lg"
                  @click="$router.push({ name: 'creez-votre-liste' })"
                />
                <div 
                  class="link link--bold link--underline"
                  @click="$router.push({ name: 'guest', params: { id: exampleListId } })"
                >
                Vedi un esempio di lista
              </div>
              </div>
            </div>
            <div class="cta-yellow-fw__cover">
              <img src="../../assets/img/covers/cover-peluche-ropa-bb-comoda.jpg" alt="" />
            </div>
          </div>
        </div>
      </section>
    </div>
  </template>
  
  <script>
  import VueSlickCarousel from "vue-slick-carousel";
  import "vue-slick-carousel/dist/vue-slick-carousel.css";
  import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
  
  import bbButton from "../../components/buttons/bbButton.vue";
  
  export default {
    name: "HomePageFR",
    metaInfo() {
      return {
        script: [
          {
            type: "application/ld+json",
            json: {
              "@context": "https://schema.org",
              "@type": "SoftwareApplication",
              name: "HelloBB",
              operatingSystem: [
                //no estic segur del format per posar més d’un valor
                "ANDROID",
                "IOS",
              ],
              applicationCategory: "LifestyleApplication",
              aggregateRating: {
                "@type": "AggregateRating",
                ratingValue: "4.5", //no aconsegueixo veure ratings, tu pots?
                ratingCount: "20", //no aconsegueixo veure ratings, tu pots?
              },
              offers: {
                "@type": "Offer",
                price: "0",
              },
            },
          },
          {
            type: "application/ld+json",
            json: {
              "@context": "https://schema.org",
              "@type": "Organization",
              name: "HelloBB",
              legalName: "HelloBB S.L.",
              url: "https://www.hellobb.net/fr",
              logo: "https://www.hellobb.net/logo-hellobb-dark.svg", //posar URL on tenim el logo
              description:
                "Fai ordini da qualsiasi negozio e ricevi regali o denaro con la lista nascita online più bella e facile da usare.",
              foundingDate: "2022",
              address: {
                "@type": "PostalAddress",
                streetAddress: "C/ Pompeu Fabra 36",
                addressLocality: "Tortosa",
                addressRegion: "Tarragona",
                postalCode: "43500",
                addressCountry: {
                  "@type": "Country",
                  name: "Spain", //en anglés millor?
                },
              },
              contactPoint: {
                "@type": "ContactPoint",
                contactType: "customer support",
                email: "hola@hellobb.net",
              },
              sameAs: [
                "https://www.instagram.com/holahellobebe/",
                "https://www.facebook.com/holahelloBB",
                "https://www.pinterest.es/holahelloBB/",
                "https://www.crunchbase.com/organization/hellobb",
              ],
            },
          },
        ],
        title: "HelloBB | Una lista nascita straordinaria come la tua famiglia",
        meta: [
          {
            name: "description",
            content:
              "Fai ordini da qualsiasi negozio e ricevi regali o denaro con la lista nascita online più bella e facile da usare.",
          },
          {
            itemprop: "image",
            content: "https://www.hellobb.net/images/cover-portada.08a0882.png",
          },
          {
            property: "og:url",
            content: "https://www.hellobb.net/fr",
          },
          {
            property: "og:type",
            content: "website",
          },
          {
            property: "og:title",
            content: "HelloBB | Una lista nascita straordinaria come la tua famiglia",
          },
          {
            property: "og:description",
            content:
              "Fai ordini da qualsiasi negozio e ricevi regali o denaro con la lista nascita online più bella e facile da usare.",
          },
          {
            property: "og:image",
            content: "https://www.hellobb.net/images/cover-portada.08a0882.png",
          },
          {
            name: "twitter:card",
            content: "summary_large_image",
          },
          {
            name: "twitter:title",
            content: "HelloBB | Una lista nascita straordinaria come la tua famiglia",
          },
          {
            name: "twitter:description",
            content:
              "Fai ordini da qualsiasi negozio e ricevi regali o denaro con la lista nascita online più bella e facile da usare.",
          },
          {
            name: "twitter:image",
            content: "https://www.hellobb.net/images/cover-portada.08a0882.png",
          },
        ],
        link: [
          { rel: "canonical", href: "https://www.hellobb.net" },
          { rel: "alternate", href: "https://www.hellobb.net/fr", hreflang: "fr" },
          { rel: "alternate", href: "https://www.hellobb.net/it", hreflang: "it" },
          { rel: "alternate", href: "https://www.hellobb.net", hreflang: "es-es" },
          { rel: "alternate", href: "https://www.hellobb.net/latam", hreflang: "es-mx" },
          { rel: "alternate", href: "https://www.hellobb.net/latam", hreflang: "es-co" },
          { rel: "alternate", href: "https://www.hellobb.net/latam", hreflang: "es-ar" },
          { rel: "alternate", href: "https://www.hellobb.net/latam", hreflang: "es-cl" },
          { rel: "alternate", href: "https://www.hellobb.net/latam", hreflang: "es-uy" },
          { rel: "alternate", href: "https://www.hellobb.net/latam", hreflang: "es-pe" },
        ],
      };
    },
    components: {
      bbButton,
      VueSlickCarousel,
    },
    data() {
      return {
        shopSlider: {
          slidesToShow: 8,
          arrows: false,
          autoplay: true,
          dots: false,
          variableWidth: false,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: "20px",
          edgeFriction: 0.35,
          speed: 500,
          responsive: [
            {
              breakpoint: 991,
              settings: {
                slidesToShow: 4,
              },
            },
            {
              variableWidth: true,
              breakpoint: 767,
              settings: {
                slidesToShow: 3,
              },
            },
          ],
        },
        reviewsSlider: {
          slidesToShow: 3,
          arrows: false,
          autoplay: true,
          slidesToScroll: 1,
          responsive: [
            {
              breakpoint: 991,
              settings: {
                slidesToShow: 2,
              },
            },
            {
              breakpoint: 767,
              settings: {
                centerMode: true,
                slidesToShow: 1,
              },
            },
          ],
        },
      };
    },
    computed: {
      exampleListId() {
        return process.env.PREVIEW_LIST_ID;
      },
    },
  };
  </script>
  
  <style lang="scss" scoped></style>
  